export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "dashboard",
        permission: "dashboards",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Phân quyền",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-manager",
        is_admin: true,
        submenu: {
          type: "mega",
          width: "800px",
          alignment: "left",
          columns: [
            {
              items: [
                {
                  title: "Danh sách quản trị",
                  page: "permissions/list",
                  permission: "get-all-user-cms",
                },
                {
                  title: "Thêm quản trị",
                  page: "permissions/add",
                  permission: "create-user-cms",
                },
                {
                  title: "Danh sách quyền",
                  page: "permissions/roles",
                  permission: "get-all-role",
                },
              ],
            },
          ],
        },
      },
      {
        title: "Đại lý",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-all-user",
        is_level3: true,
        submenu: {
          type: "mega",
          width: "800px",
          alignment: "left",
          columns: [
            {
              items: [
                {
                  title: "Danh sách",
                  page: "distributor/list",
                  permission: "get-distributor",
                },
                {
                  title: "Tạo đại lý",
                  page: "distributor/add",
                  permission: "add-distributor",
                },
                {
                  title: "TOP",
                  page: "distributor/top",
                  is_admin: true,
                  permission: "top-distributor",
                },
              ],
            },
          ],
        },
      },
      {
        title: "Đơn hàng",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-all-order",
        submenu: {
          type: "mega",
          width: "800px",
          alignment: "left",
          columns: [
            {
              items: [
                {
                  title: "Danh sách đơn hàng lẻ",
                  page: "ListOrder",
                  permission: "get-order-single",
                },
                {
                  title: "Danh sách đơn hàng sỉ",
                  page: "wholeSale",
                  permission: "get-order-wholeSale",
                },
                {
                  title: "Đơn hàng mua sỉ",
                  page: "wholeBuySale",
                  permission: "get-order-buy-wholesale",
                  is_distributor: true,
                },
                {
                  title: "Tạo đơn hàng",
                  page: "order/add",
                  permission: "add-order",
                },
                {
                  title: "Tặng key 1 tháng",
                  page: "order/send-trial",
                  permission: "add-order-trial",
                  is_admin: true,
                },
              ],
            },
          ],
        },
      },
      {
        title: "Khách hàng",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-all-user",
        page: "customers",
        is_distributor: true,
      },
      {
        title: "Quản lý key",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-key",
        page: "keyManage",
      },
      // {
      //   title: "Công cụ",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-cogwheel-2",
      //   permission: "tool",
      //   is_admin: true,
      //   submenu: {
      //     type: "mega",
      //     width: "800px",
      //     alignment: "left",
      //     columns: [
      //       {
      //         items: [
      //           {
      //             title: "Xem tài khoản NPP",
      //             page: "tools/viewDistributor",
      //             permission: "tool-switch",
      //           },
      //           {
      //             title: "Thay đổi mật khẩu NPP",
      //             page: "tools/changePassDistributor",
      //             permission: "change-pass",
      //           },
      //           {
      //             title: "Cập nhật NPP",
      //             page: "tools/updateDistributor",
      //             permission: "update-info-distributors",
      //           },
      //           {
      //             title: "Gửi Mail",
      //             page: "tools/sendEmail",
      //             permission: "send-email",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // },
      {
        title: "Thông báo",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-all-notify",
        is_admin: true,
        submenu: {
          type: "mega",
          width: "800px",
          alignment: "left",
          columns: [
            {
              items: [
                {
                  title: "Danh sách",
                  page: "notify",
                  permission: "get-all-notify",
                },
                {
                  title: "Tạo thông báo",
                  page: "notify/create",
                  permission: "create-notify",
                },
              ],
            },
          ],
        },
      },
      {
        title: "Training",
        root: true,
        alignment: "left",
        toggle: "click",
        permission: "get-all-video-training",
        submenu: {
          type: "mega",
          width: "800px",
          alignment: "left",
          columns: [
            {
              items: [
                {
                  title: "Video",
                  page: "training/video",
                  permission: "get-all-video-training",
                },
                {
                  title: "Tài liệu",
                  page: "training/document",
                  permission: "get-all-docs-training",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        permission: "get-dashboard",
        bullet: "dot",
      },
      {
        title: "Phân quyền",
        root: true,
        bullet: "dot",
        icon: "flaticon2-user",
        permission: "get-manager",
        is_admin: true,
        submenu: [
          {
            title: "Danh sách quản trị",
            page: "permissions/list",
            permission: "get-manager",
          },
          {
            title: "Thêm quản trị",
            page: "permissions/add",
            permission: "add-manager",
          },
          {
            title: "Danh sách quyền",
            page: "permissions/roles",
            permission: "get-all-role",
          },
        ],
      },
      {
        title: "Đại lý",
        root: true,
        bullet: "dot",
        icon: "flaticon2-group",
        permission: "distributor",
        submenu: [
          {
            title: "Danh sách",
            page: "distributor/list",
            permission: "get-distributor",
            is_level3: true,
          },
          {
            title: "Tạo đại lý",
            page: "distributor/add",
            permission: "add-distributor",
            is_distributor: true,
          },
          {
            title: "Chờ duyệt",
            page: "distributor/pending",
            permission: "get-distributor",
            is_admin: true,
          },
          {
            title: "TOP",
            page: "distributor/top",
            is_admin: true,
            permission: "top-distributor",
          },
          {
            title: "Hoa hồng đại lý",
            page: "distributor/commission",
            permission: "view-commission",
            is_admin: true,
          },
          // {
          //   title: "TOP",
          //   page: "distributor/child/top",
          //   is_distributor: true,
          //   permission: "top-distributor",
          // },
          // {
          //   title: "Hoa hồng đại lý",
          //   page: "distributor/child/commission",
          //   permission: "view-commission",
          //   is_distributor: true,
          // },
        ],
      },
      {
        title: "Hệ thống CTV",
        root: true,
        bullet: "dot",
        icon: "flaticon2-group",
        permission: "aff",
        // is_distributor: true,
        submenu: [
          {
            title: "Danh sách CTV",
            page: "aff/list",
            permission: "get-aff",
          },
          {
            title: "Đăng ký khoá học",
            page: "aff/submit-course",
            permission: "get-aff",
          }
        ],
      },
      {
        title: "Link giới thiệu",
        root: true,
        bullet: "dot",
        icon: "flaticon2-group",
        permission: "intro-link",
        is_distributor: true,
        page: "intro-link"
      },
      {
        title: "Bán hàng",
        root: true,
        bullet: "dot",
        icon: "flaticon2-shopping-cart-1",
        permission: "get-orders",
        is_distributor: true,
        submenu: [
          {
            title: "Đơn hàng",
            root: true,
            bullet: "line",
            permission: "get-orders",
            submenu: [
              {
                title: "Đơn hàng bán lẻ",
                page: "order/list-single",
                permission: "get-order-single",
              },
              // {
              //   title: "Đơn bán gói Đại lý",
              //   page: "order/list-wholeSale",
              //   permission: "get-order-wholesale",
              // },
              {
                title: "Tạo đơn bán lẻ",
                page: "order/single-add",
                permission: "add-order-single"
              },
              {
                title: "Đơn mua gói Đại lý",
                page: "order/wholeBuySale",
                permission: "get-order-buy-wholesale",
              },
              {
                title: "Tạo đơn mua gói Đại lý",
                page: "order/wholeSale-add",
                permission: "add-order-buy-wholesale",
              },
            ],
          },
          {
            title: "Khách hàng",
            root: true,
            bullet: "dot",
            permission: "get-customer",
            page: "customers",
          },
          // {
          //   title: "Hoa hồng",
          //   root: true,
          //   bullet: "dot",
          //   permission: "get-customer",
          //   page: "commissions",
          // },
        ]
      },
      {
        title: "Đơn hàng",
        root: true,
        bullet: "dot",
        icon: "flaticon2-shopping-cart-1",
        permission: "get-orders",
        is_admin: true,
        submenu: [
          {
            title: "Đơn hàng bán lẻ",
            page: "order/list-single",
            permission: "get-order-single",
          },
          {
            title: "Tạo đơn bán lẻ",
            page: "order/single-add",
            permission: "add-order-single"
          },
          {
            title: "Đơn bán gói Đại lý",
            page: "order/list-wholeSale",
            permission: "get-order-wholesale",
          },
          {
            title: "Tạo đơn bán gói ĐL",
            page: "order/order-package-add",
            permission: "add-order-single"
          },
        ],
      },
      {
        title: "Khách hàng",
        root: true,
        bullet: "dot",
        icon: "flaticon-customer",
        permission: "get-customer",
        page: "customers",
        is_admin: true,
      },
      {
        title: "Quản lý key",
        root: true,
        bullet: "dot",
        icon: "flaticon2-layers-1",
        permission: "get-key",
        page: "key-balance",
        is_distributor: true,
        // submenu: [
          // {
          //   title: "Quản lý key",
          //   page: "key-balance",
          //   permission: "get-key",
          //   is_distributor: true,
          // },
          // {
          //   title: "Quản lý ví điểm",
          //   root: true,
          //   bullet: "line",
          //   permission: "get-deposit-credit",
          //   submenu: [
          //     {
          //       title: "Số dư ví điểm",
          //       page: "deposit/balance",
          //       permission: "credit-view",
          //     },
          //     {
          //       title: "Nạp thêm ví điểm",
          //       page: "deposit/add",
          //       permission: "credit-topup",
          //     },
          //     {
          //       title: "Giao dịch nạp ví điểm",
          //       page: "deposit/list",
          //       permission: "confirm-deposit",
          //     },
          //     {
          //       title: "Chuyển ví điểm",
          //       page: "deposit/history",
          //       permission: "send-point",
          //     },
          //   ],
          // },
        // ]
      },
      // {
      //   title: "Quản lý ví điểm",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon2-analytics-1",
      //   permission: "get-deposit-credit",
      //   is_admin: true,
      //   submenu: [
      //     {
      //       title: "Ví điểm của NPP",
      //       page: "deposit/agency",
      //       permission: "manager-deposit",
      //     },
      //     {
      //       title: "Giao dịch nạp ví điểm",
      //       page: "deposit/list",
      //       permission: "confirm-deposit",
      //     },
      //     {
      //       title: "Lịch sử Admin chuyển ví điểm",
      //       page: "deposit/history",
      //       permission: "send-point",
      //     },
      //     {
      //       title: "Cấu hình ngân hàng nhận tiền",
      //       page: "deposit/bank",
      //       permission: "config-bank",
      //       is_admin: true,
      //     },
      //   ],
      // },
      {
        title: "Quản lý sản phẩm",
        root: true,
        bullet: "dot",
        icon: "flaticon2-box",
        permission: "get-all-product",
        is_admin: true,
        submenu: [
          {
            title: "Danh sách sản phẩm",
            page: "product/list",
            permission: "get-all-product",
          },
          {
            title: "Danh sách gói sản phẩm",
            page: "product/package",
            permission: "get-package-product",
          },
          {
            title: "Danh sách gói Đại lý",
            page: "product/agency",
            permission: "get-package-product",
          },
        ],
      },
      {
        title: "Quản lý khoá học",
        root: true,
        bullet: "dot",
        icon: "flaticon-support",
        permission: "course-manager",
        page: "course-management",
      },
      {
        title: "Quản lý thông báo",
        root: true,
        bullet: "dot",
        icon: "flaticon-alert",
        permission: "notify",
        is_admin: true,
        submenu: [
          {
            title: "Danh sách thông báo",
            page: "notify/list",
            permission: "get-notify",
          },
          {
            title: "Tạo thông báo",
            page: "notify/create",
            permission: "add-notify",
          },
        ],
      },
      {
        title: "Thông báo Đại lý tuyến dưới",
        root: true,
        bullet: "dot",
        icon: "flaticon-alert",
        permission: "notify",
        is_distributor: true,
        submenu: [
          {
            title: "Danh sách",
            page: "notify/list",
            permission: "get-notify",
          },
          {
            title: "Tạo thông báo",
            page: "notify/create",
            permission: "add-notify",
          },
        ],
      },
      // {
      //   title: "Công cụ",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-cogwheel-2",
      //   permission: "tool",
      //   is_admin: true,
      //   submenu: [
      //     {
      //       title: "Thay đổi mật khẩu NPP",
      //       page: "tools/changePassDistributor",
      //       permission: "change-pass",
      //     },
      //     {
      //       title: "Cập nhật NPP",
      //       page: "tools/updateDistributor",
      //       permission: "update-info-distributors",
      //     },
      //   ],
      // },
      {
        title: "CSKH",
        root: true,
        bullet: "dot",
        icon: "flaticon-support",
        permission: "created-mail-cskh",
        page: "support-customer/create",
      },
      {
        title: "Thông tin hỗ trợ",
        root: true,
        bullet: "dot",
        icon: "flaticon2-writing",
        permission: "get-training",
        is_distributor: true,
        submenu: [
          {
            title: "Training",
            root: true,
            bullet: "line",
            permission: "get-training",
            submenu: [
              {
                title: "Video",
                page: "training/video",
                permission: "get-training",
              },
              {
                title: "Tài liệu",
                page: "training/document",
                permission: "get-training",
              },
              {
                title: "Lịch đào tạo",
                page: "training/schedule",
                permission: "get-schedule",
              },
            ],
          },
          {
            title: "Chính sách",
            root: true,
            bullet: "dot",
            permission: "get-policy",
            page: "policy/list",
          },
        ]
      },
      {
        title: "Training",
        root: true,
        bullet: "dot",
        icon: "flaticon2-writing",
        permission: "get-training",
        is_admin: true,
        submenu: [
          {
            title: "Video",
            page: "training/video",
            permission: "get-training",
          },
          {
            title: "Tài liệu",
            page: "training/document",
            permission: "get-training",
          },
          {
            title: "Lịch đào tạo",
            page: "training/schedule",
            permission: "get-schedule",
          },
        ],
      },
      {
        title: "Chính sách",
        root: true,
        bullet: "dot",
        icon: "flaticon-security",
        permission: "get-policy",
        is_admin: true,
        submenu: [
          {
            title: "Danh sách",
            page: "policy/list",
            permission: "get-policy",
          },
          {
            title: "Thêm chính sách",
            page: "policy/add",
            permission: "get-policy",
          },
        ],
      },
    ],
  },
  asideCollab: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        bullet: "dot",
        icon: "flaticon2-architecture-and-city",
        permission: "collab-permission",
        page: "dashboard",
      },
      {
        title: "Đơn hàng",
        root: true,
        bullet: "dot",
        icon: "flaticon2-shopping-cart-1",
        permission: "collab-permission",
        page: "collaborator/order",
      },
      {
        title: "Khách hàng",
        root: true,
        bullet: "dot",
        icon: "flaticon-customer",
        permission: "collab-permission",
        page: "collaborator/customer",
      },
      {
        title: "Thông tin ngân hàng",
        root: true,
        bullet: "dot",
        icon: "flaticon-car",
        permission: "collab-permission",
        page: "collaborator/bankInfo",
      },
      // {
      // 	title: "Lịch sử thanh toán",
      // 	root: true,
      // 	bullet: "dot",
      // 	icon: "flaticon-piggy-bank",
      // 	permission: "collab-permission",
      // 	page: "collaborator/collab-history-payment",
      // },
      {
        title: "Thông báo",
        root: true,
        bullet: "dot",
        icon: "flaticon2-notification",
        permission: "collab-permission",
        page: "collaborator/notification",
      },
      {
        title: "Training",
        root: true,
        bullet: "dot",
        icon: "flaticon2-writing",
        permission: "collab-permission",
        submenu: [
          {
            title: "Video",
            page: "training/video",
            permission: "collab-permission",
          },
          {
            title: "Tài liệu",
            page: "training/document",
            permission: "collab-permission",
          },
        ],
      },
    ],
  },
  asideNewAgency: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        bullet: "dot",
        icon: "flaticon2-architecture-and-city",
        permission: "collab-permission",
        page: "dashboard",
      },
      {
        title: "Quản lý credit",
        root: true,
        bullet: "dot",
        icon: "flaticon2-analytics-1",
        permission: "get-deposit-credit",
        submenu: [
          {
            title: "Số dư credit",
            page: "deposit/balance",
            permission: "get-deposit-credit",
            is_distributor: true,
          },
          {
            title: "Nạp thêm credit",
            page: "deposit/add",
            permission: "get-deposit-credit",
            is_distributor: true,
          },
          {
            title: "Giao dịch nạp credit",
            page: "deposit/list",
            permission: "get-deposit-credit",
          },
        ],
      },
      {
        title: "Quản lý sản phẩm",
        root: true,
        bullet: "dot",
        icon: "flaticon2-box",
        permission: "get-all-product",
        submenu: [
          {
            title: "Danh sách sản phẩm",
            page: "product/list",
            permission: "get-all-product",
          },
          {
            title: "Gói sản phẩm",
            page: "product/package",
            permission: "get-package-product",
          },
          {
            title: "Tạo sản phẩm",
            page: "product/create-update-product",
            permission: "create-update-product",
          },
          {
            title: "Tạo gói sản phẩm",
            page: "product/cu-package-product",
            permission: "get-package-product",
          },
        ],
      },
      {
        title: "Mua hàng thành đại lý",
        root: true,
        bullet: "dot",
        icon: "flaticon2-shopping-cart-1",
        permission: "collab-permission",
        page: "agency/submit",
      },
      {
        title: "Training",
        root: true,
        bullet: "dot",
        icon: "flaticon2-writing",
        permission: "collab-permission",
        submenu: [
          {
            title: "Video",
            page: "training/video",
            permission: "collab-permission",
          },
          {
            title: "Tài liệu",
            page: "training/document",
            permission: "collab-permission",
          },
        ],
      },
    ],
  },
};
