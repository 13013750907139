import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

{
  /* =============Dashboard============= */
}
const Dashboard = lazy(() => import("../dashboard/Dashboard"));

// const dashboardColla = lazy(() => import("../collaborators/DasboardColla"));
{
  /* ==================================== */
}
{
  /* =============Order============= */
}
const CreateOrderPackage = lazy(() =>
  import("../contract/CreateOrderPackage")
);

const CreateOrderWholseSale = lazy(() =>
  import("../contract/CreateOrderWholseSale")
);

const createWholeSale = lazy(() => import("../contract/createWholeSale"));
const createKeyForDistributor = lazy(() =>
  import("../contract/createKeyForDistributor")
);

const ListOrder = lazy(() => import("../contract/ListOrder"));

const OrderDetail = lazy(() => import("../contract/OrderDetail"));

const createOrder = lazy(() => import("../contract/createOrder"));

const wholeSale = lazy(() => import("../contract/WholeSaleOrder"));

const wholeSaleBuy = lazy(() => import("../contract/WholeSaleBuyOrder"));

const OrderTrial = lazy(() => import("../contract/OrderTrial"));

const ListDeposit = lazy(() => import("../deposit/ListDeposit"));
const AddDeposit = lazy(() => import("../deposit/AddDeposit"));
const AgencyCredit = lazy(() => import("../deposit/AgencyCredit"));
const CreditDetail = lazy(() => import("../deposit/CreditDetail"));
const HistoryPaymentSend = lazy(() => import("../deposit/HistoryAdminSend"));
const AdminSend = lazy(() => import("../deposit/AdminSend"));

const ListProduct = lazy(() => import("../product/ListProduct"));
const CourseManagement = lazy(() => import("../product/CourseManagement"));
const ListPackageMember = lazy(() => import("../product/ListPackageMember"));
const PackageProduct = lazy(() => import("../product/ListProductPackage"));
// const CreateUpdateProduct = lazy(() =>
//   import("../product/CreateUpdateProduct")
// );
// const CUPackageProduct = lazy(() => import("../product/CUPackageProduct"));

const OrderSubmit = lazy(() => import("../agency/OrderSubmit"));
const AgencyManagement = lazy(() => import("../agency/AgencyManagement"));
const CreateAgency = lazy(() => import("../agency/CreateAgency"));

{
  /* ==================================== */
}
{
  /* =============Key============= */
}
// const ListExchangeKey = lazy(() => import("../exchangeKey/listExchangeKey"));

// const ListRequestExchange = lazy(() => import("../exchangeKey/ListRequest"));

const SendTrial = lazy(() => import("../contract/SendTrial"));

const keyManage = lazy(() => import("../keyManager/KeyManage"));

const AssetKey = lazy(() => import("../keyManager/AssetKey"));
{
  /* ==================================== */
}
{
  /* =============Training============= */
}
const VideoTraining = lazy(() => import("../training/VideoTraining"));

const TrainingSchedule = lazy(() => import("../training/TrainingSchedule"));
const ListTraining = lazy(() => import("../training/ListTraining"));
const DocumentTraining = lazy(() => import("../training/DocumentTraining"));
const CreatePolicy = lazy(() => import("../policy/AddPolicy"));
const ListPolicy = lazy(() => import("../policy/List"));
const DetailsPolicy = lazy(() => import("../policy/DetailsPolicy"));

const DetailsTraing = lazy(() => import("../training/DetailsTraing"));

{
  /* ==================================== */
}
{
  /* =============Customer============= */
}
const ListCustomer = lazy(() => import("../customer/ListCustomer"));
{
  /* ==================================== */
}
{
  /* =============Account============= */
}
const Profile = lazy(() => import("../account/Profile"));
{
  /* ==================================== */
}
{
  /* =============Tool============= */
}
const LoginDistributor = lazy(() => import("../auth/LoginDistributor"));
const ChangePassDistributor = lazy(() =>
  import("../auth/ChangePassDistributor")
);
const UpdateDistributor = lazy(() => import("../auth/UpdateDistributor"));
const SendEmail = lazy(() => import("../auth/SendEmail"));
{
  /* ==================================== */
}
{
  /* =============Report============= */
}
const AllSystem = lazy(() => import("../report/AllSystem"));
{
  /* ==================================== */
}

/* =============AFF============= */
const ListAff = lazy(() => import("../affiliate/List"));
const SubmitCourse = lazy(() => import("../affiliate/SubmitCourse"));
const LinkAff = lazy(() => import("../affiliate/Link"));

{
  /* =============Distributor============= */
}
const DetailDistributor = lazy(() =>
  import("../distributor/DetailDistributor")
);
const SettingProduct = lazy(() => import("../distributor/SettingProduct"));

const IntroduceDistributor = lazy(() =>
  import("../distributor/IntroduceDistributor")
);

const ListDistriIntro = lazy(() => import("../distributor/ListDistriIntro"));
const HistoryPaymentDistributor = lazy(() =>
  import("../distributor/HistoryPayment")
);

const ListDistributors = lazy(() => import("../distributor/ListDistributors"));
const DistributorsPending = lazy(() =>
  import("../distributor/DistributorsPending")
);

const AddEditDistributor = lazy(() =>
  import("../distributor/AddEditDistributor")
);

const TopDistributor = lazy(() => import("../distributor/TopDistributor"));
const TopDisLower = lazy(() => import("../distributor/TopDisLower"));

const Potential = lazy(() => import("../distributor/potential"));
const TreeDistributor = lazy(() => import("../distributor/TreeDistributor"));
const CommissionDistributor = lazy(() => import("../distributor/Commission"));
{
  /* ==================================== */
}
{
  /* =============Notify============= */
}
const ListNotity = lazy(() => import("../notify/ListNotify"));

const CreateNotify = lazy(() => import("../notify/CreateNotify"));

const NotifyDistributor = lazy(() => import("../notify/NotifyDistributor"));
{
  /* ==================================== */
}
{
  /* =============Permission Manager============= */
}
const EditUser = lazy(() => import("../users/CreateUser"));

const CreateUser = lazy(() => import("../users/CreateUser"));

const ListUser = lazy(() => import("../users/ListUser"));

const ListRole = lazy(() => import("../users/role/ListRole"));

const EditRole = lazy(() => import("../users/role/EditRole"));

const CreateRole = lazy(() => import("../users/role/CreateRole"));

const EditPermissionRole = lazy(() =>
  import("../users/role/EditPermissionRole")
);
{
  /* ==================================== */
}
{
  /* =============Coupons============= */
}
const DonateCoupons = lazy(() => import("../giftCard/DonateCoupons"));

const AddCampaign = lazy(() => import("../giftCard/AddCampaign"));

const AddVoucher = lazy(() => import("../giftCard/AddVoucher"));

const ListKeyDiscount = lazy(() => import("../giftCard/ListKeyDiscount"));

const ListCampaign = lazy(() => import("../giftCard/ListCampaign"));

const ListCoupons = lazy(() => import("../giftCard/ListCoupons"));

const GiveCoupons = lazy(() => import("../giftCard/GiveCoupons"));
{
  /* ==================================== */
}
{
  /* =============Collaborator Notify============= */
}
// const ListNotityCollab = lazy(() => import("../collabNotify/ListNotify"));

// const CreateCollabNotify = lazy(() => import("../collabNotify/CreateNotify"));
{
  /* ==================================== */
}
{
  /* =============Collaborator for Distributor============= */
}

// const CustomerColla = lazy(() => import("../collaborators/CustomerInvited"));

{
  /* =============Collaborator for Admin============= */
}

// const ListCollaboratorsAdmin = lazy(() =>
//   import("../collaboratorsAdmin/ListCollaboratorsAdmin")
// );

// const DetailCollaboratorAdmin = lazy(() =>
//   import("../collaboratorsAdmin/DetailCollaboratorAdmin")
// );

// const HistoryPaymentAdmin = lazy(() =>
//   import("../collaboratorsAdmin/HistoryPaymentAdmin")
// );
{
  /* ==================================== */
}
{
  /* =============Support Customer============= */
}
const SupportCustomer = lazy(() =>
  import("../supportCustomer/SupportCustomer")
);
{
  /* ==================================== */
}
{
  /* =============Error Page============= */
}
const Error403 = lazy(() => import("../common/Error403"));
{
  /* ==================================== */
}
{
  /* =============Distributor Bank============= */
}
const ListBank = lazy(() => import("../bank/ListBank"));
{
  /* ==================================== */
}
// const UploadLandingPage = lazy(() => import("../LandingPage/UploadLanding"));
{
  /* =============Payment============= */
}
const LinkPayment = lazy(() => import("../linkDistribution/LinkDistribution"));

// const LinkColla = lazy(() => import("../collaborators/LinkCollaborator"));

const ListOrderDistri = lazy(() => import("../contract/ListOrderDistri"));

// const BankInfo = lazy(() => import("../collaborators/BankInfo"));

// const HistoryPayment = lazy(() =>
//   import("../collaboratorsDistri/HistoryPayment")
// );

// const CollabHistoryPayment = lazy(() =>
//   import("../collaborators/CollabHistoryPayment")
// );

// const ListExportPdf = lazy(() => import("../exportPDF/List"));

// const ChooseProduct = lazy(() => import("../product/ChooseProduct"));

// const EditLandingPage = lazy(() => import("../LandingPage/EditLandingPage"));

const Commissions = lazy(() => import("../commission/CommissionRef"));
const CommissionsDistributor = lazy(() =>
  import("../commission/CommissionDistributor")
);

const SettingCommission = lazy(() => import("../commission/SettingCommission"));

const BankSystem = lazy(() => import("../bank/BankSystem1"));

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          // <Redirect exact from="/" to="/product/chooseProduct" />
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <Route exact path="/product/chooseProduct" component={ChooseProduct} />  */}

        <Route exact path="/aff/list" component={ListAff} />
        <Route exact path="/aff/submit-course" component={SubmitCourse} />
        <Route exact path="/intro-link" component={LinkAff} />

        {/* Route distributor */}
        <Route exact path="/distributor/list" component={ListDistributors} />
        <Route
          exact
          path="/distributor/pending"
          component={DistributorsPending}
        />
        <Route
          exact
          path="/distributor/edit/:id"
          component={ListDistributors}
        />
        <Route exact path="/distributor/add" component={AddEditDistributor} />
        <Route exact path="/distributor/top" component={TopDistributor} />
        <Route exact path="/distributor/child/top" component={TopDisLower} />
        <Route
          exact
          path="/distributor/detail/:id"
          component={DetailDistributor}
        />
        <Route
          exact
          path="/distributor/intro-distributor"
          component={IntroduceDistributor}
        />
        <Route
          exact
          path="/distributor/all-intro"
          component={ListDistriIntro}
        />
        <Route
          exact
          path="/distributor/history-payment"
          component={HistoryPaymentDistributor}
        />
        <Route exact path="/distributor/potential" component={Potential} />
        <Route exact path="/distributor/tree" component={TreeDistributor} />
        <Route
          exact
          path="/distributor/commission"
          component={CommissionDistributor}
        />
        {/* Route bank */}
        <Route exact path="/bank/info" component={ListBank} />
        <Route exact path="/deposit/bank" component={BankSystem} />
        {/* Route order */}
        <Route exact path="/order/list-single" component={ListOrder} />
        <Route exact path="/order/detail/:id" component={OrderDetail} />
        <Route exact path="/order/single-add" component={createOrder} />
        <Route exact path="/order-trial" component={OrderTrial} />
        <Route
          exact
          path="/order/createKeyForDistributor"
          component={createKeyForDistributor}
        />
        <Route exact path="/order/createSale" component={createWholeSale} />
        <Route
          exact
          path="/order/wholeSale-add"
          component={CreateOrderWholseSale}
        />
        <Route
          exact
          path="/order/order-package-add"
          component={CreateOrderPackage}
        />
        <Route exact path="/order/list-wholeSale" component={wholeSale} />
        <Route exact path="/order/wholeBuySale" component={wholeSaleBuy} />
        <Route exact path="/order/send-trial" component={SendTrial} />
        {/* <Route exact path="/order/collab-order" component={CollabOrder} /> */}
        <Route exact path="/order/intro-order" component={ListOrderDistri} />
        {/* Route link payment */}
        <Route exact path="/link" component={LinkPayment} />
        {/* Route customer */}
        <Route exact path="/customers" component={ListCustomer} />
        {/* Route CTV */}
        {/* <Route
          exact
          path="/collaborator/notification"
          component={NoticationCollab}
        /> */}
        {/* <Route exact path="/collaborator/order" component={OrderColla} />
        <Route exact path="/collaborator/customer" component={CustomerColla} />
        <Route exact path="/collaborator/link" component={LinkColla} /> */}
        {/* <Route
          exact
          path="/collaborator/dashboard"
          component={dashboardColla}
        /> */}
        {/* <Route exact path="/collaborator/bankInfo" component={BankInfo} /> */}
        {/* <Route
          exact
          path="/collaborator/collab-history-payment"
          component={CollabHistoryPayment}
        /> */}
        {/* Route profile */}
        <Route exact path="/profile" component={Profile} />
        {/* Route Report */}
        <Route exact path="/report/system" component={AllSystem} />
        {/* Route nofity */}
        <Route exact path="/notify/create" component={CreateNotify} />
        <Route exact path="/notify/list" component={ListNotity} />
        <Route exact path="/notify/distributor" component={NotifyDistributor} />
        {/*Route collaborator notify */}
        {/* <Route
          exact
          path="/notifycollab/create"
          component={CreateCollabNotify}
        /> */}
        {/* <Route exact path="/notifycollab/list" component={ListNotityCollab} /> */}
        {/* Route training */}
        <Route exact path="/training/video" component={VideoTraining} />
        <Route
          exact
          path="/training/schedule-create"
          component={TrainingSchedule}
        />{" "}
        <Route
          exact
          path="/training/schedule-update/:id"
          component={TrainingSchedule}
        />
        <Route exact path="/training/detail/:id" component={DetailsTraing} />
        <Route
          exact
          path="/training/schedule-details"
          component={DetailsTraing}
        />
        <Route exact path="/training/schedule" component={ListTraining} />
        <Route exact path="/policy/list" component={ListPolicy} />
        <Route exact path="/policy/detail/:id" component={DetailsPolicy} />
        <Route exact path="/policy/update/:id" component={CreatePolicy} />
        <Route exact path="/policy/add" component={CreatePolicy} />
        <Route exact path="/training/document" component={DocumentTraining} />
        {/* Route training */}
        <Route exact path="/training/video" component={VideoTraining} />
        <Route exact path="/training/document" component={DocumentTraining} />
        {/* Route permisions */}
        <Route exact path="/permissions/roles" component={ListRole} />
        <Route exact path="/permissions/roles/edit/:id" component={EditRole} />
        <Route
          exact
          path="/permissions/roles/edit-permission/:id"
          component={EditPermissionRole}
        />
        <Route exact path="/permissions/roles/add" component={CreateRole} />
        <Route exact path="/permissions/list" component={ListUser} />
        <Route exact path="/permissions/add" component={CreateUser} />
        <Route exact path="/permissions/edit/:id" component={EditUser} />
        {/* Route product */}
        <Route
          exact
          path="/distributor/settingProduct/:id"
          component={SettingProduct}
        />
        {/* Route tools */}
        <Route
          exact
          path="/tools/viewDistributor"
          component={LoginDistributor}
        />
        <Route
          exact
          path="/tools/updateDistributor"
          component={UpdateDistributor}
        />
        <Route
          exact
          path="/tools/changePassDistributor"
          component={ChangePassDistributor}
        />
        <Route exact path="/tools/sendEmail" component={SendEmail} />
        {/* <Route
          exact
          path="/tools/editLandingPage"
          component={EditLandingPage}
        />
        <Route
          exact
          path="/landingPage/editLandingPage"
          component={EditLandingPage}
        /> */}
        {/* Route Key */}
        <Route exact path="/key-balance" component={AssetKey} />
        <Route exact path="/keyManage" component={keyManage} />
        <Route exact path="/deposit/list" component={ListDeposit} />
        <Route exact path="/deposit/add" component={AddDeposit} />
        <Route exact path="/deposit/agency" component={AgencyCredit} />
        <Route exact path="/deposit/balance" component={CreditDetail} />
        <Route exact path="/deposit/history" component={HistoryPaymentSend} />
        <Route exact path="/deposit/send" component={AdminSend} />
        <Route exact path="/agency/submit" component={OrderSubmit} />
        <Route exact path="/agency/management" component={AgencyManagement} />
        <Route
          exact
          path="/agency/management-create"
          component={CreateAgency}
        />
        {/* Route exchange key */}
        <Route exact path="/product/list" component={ListProduct} />
        <Route exact path="/product/agency" component={ListPackageMember} />
        <Route exact path="/product/package" component={PackageProduct} />
        <Route exact path="/course-management" component={CourseManagement} />
        
        {/* <Route
          exact
          path="/product/create-update-product"
          component={CreateUpdateProduct}
        />
        <Route
          exact
          path="/product/cu-package-product"
          component={CUPackageProduct}
        /> */}
        <Route exact path="/agency/submit" component={OrderSubmit} />
        {/* Route exchange key */}
        {/* <Route exact path="/exchange-key" component={ListExchangeKey} />
        <Route exact path="/exchange/request" component={ListRequestExchange} />
        <Route exact path="/export/pdf" component={ListExportPdf} /> */}
        {/* Route gift card */}
        <Route
          exact
          path="/gift-card/donate-coupons"
          component={DonateCoupons}
        />
        <Route exact path="/gift-card/add-campaign" component={AddCampaign} />
        <Route exact path="/gift-voucher/export" component={AddVoucher} />
        <Route exact path="/gift-voucher/list" component={ListKeyDiscount} />
        <Route exact path="/gift-card/list-campaign" component={ListCampaign} />
        <Route exact path="/gift-card/all-coupons" component={ListCoupons} />
        <Route exact path="/gift-card/give-coupons" component={GiveCoupons} />
        {/* Route Collaborators for Distributor*/}
        {/* <Route exact path="/collaborators/list" component={ListCollaborators} />
        <Route exact path="/collaborators/add" component={AddCollaboration} /> */}
        {/* <Route
          exact
          path="/collaborators/detail/:id"
          component={DetailCollaborator}
        />
        <Route
          exact
          path="/collaborators/history-payment"
          component={HistoryPayment}
        /> */}
        {/* Route Collaborators for Admin */}
        {/* <Route
          exact
          path="/collaborators-admin/list"
          component={ListCollaboratorsAdmin}
        />
        <Route
          exact
          path="/collaborators-admin/detail/:id"
          component={DetailCollaboratorAdmin}
        />
        <Route
          exact
          path="/collaborators-admin/history-payment"
          component={HistoryPaymentAdmin}
        /> */}
        {/* Route CSKH */}
        <Route
          exact
          path="/support-customer/create"
          component={SupportCustomer}
        />
        {/* <Route
          path="/landingPage/uploadLandindPage"
          component={UploadLandingPage}
        /> */}
        {/* Route other */}
        <Route path="/dashboard" component={Dashboard} />
        <Route
          exact
          path="/commissions/setting"
          component={SettingCommission}
        />
        <Route exact path="/commissions" component={Commissions} />
        <Route
          exact
          path="/distributor/child/commission"
          component={CommissionsDistributor}
        />
        {/* <Redirect to="Error403" /> */}
        <Route path="/Error403" component={Error403} />
      </Switch>
    </Suspense>
  );
}
