import React from "react";
import Notification from "../../../app/partials/layout/Notification";
import UpLevel from "../../../app/partials/layout/UpLevel";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import { connect } from "react-redux";
import Level from "./Level";
import makeRequest from "../../../app/libs/request";
import * as actions from "./../../../app/store/actions/index";

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listNotify: [],
      showModal: false,
      totalUnRead: 0,
      conditionUp: 0,
      totalPoints: 0,
      user: {},
      totalOrder: 0,

    };
  }

  componentDidMount = () => {
    this.getUserInfo();
    this.getTotalPoints();
    this.getTotalOrderProductDistributor();
    if(this.state.user.distributor_id){
      this.getConditionUpLevel();
    }

  };

  getNotify = async () => {
    makeRequest("get", "notification/GetAllNotifyDetailDistri")
      .then(({ data }) => {
        if (data.signal) {
          this.setState({
            listNotify: data.data.rows,
            totalUnRead: data.data.total
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getConditionUpLevel = async () => {
    makeRequest("get", `distributor/getconditionuplevel`)
      .then(({ data }) => {
        this.setState({
          conditionUp: data.data,
        });

      })
      .catch(err => {
        console.log(err);
      });
  };
  getTotalOrderProductDistributor = async () => {
    makeRequest(
      "get",
      `order/getTotalOrderProductDistributor`
    )
      .then(({ data }) => {
        this.props.onStateClick(data.data.total);
      })
      .catch(err => {
        console.log(err);
      });

  };
  getTotalPoints = async () => {
    makeRequest("get", `distributor/getTotalPoints`)
      .then(({ data }) => {
        this.setState({ totalPoints: data.data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  getUserInfo = async () => {
    makeRequest("get", `profile/userInfo`)
      .then(({ data }) => {
        this.setState({
          user: data.data
        });
        if(data.data.distributor_id){
          this.getConditionUpLevel();
        }
        
        if (!data.data.collaborator_id) {
          this.getNotify();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  setStatus = id => {
    let objTemp = [...this.state.listNotify];
    objTemp.find(x => x.id == id).status = 1;
    this.setState({
      listNotify: objTemp
    });
  };

  render() {
    let {
      listNotify,
      totalUnRead,
      conditionUp,
      totalPoints,
      user,
      totalOrder,
    } = this.state;

    return (
      <div className="kt-header__topbar">
        {user && user.distributor_id ? (
          <UpLevel
            conditionUp={conditionUp}
            totalPoints={totalPoints}
            user={user}
          />
        ) : null}
        {user && !user.collaborator_id ? (
          <Notification
            bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
            pulse="true"
            pulseLight="false"
            skin="dark"
            iconType=""
            type="success"
            dot="false"
            listNotify={listNotify}
            totalUnRead={totalUnRead}
            setStatus={this.setStatus}
          />
        ) : null}

        {/* <UserNotifications
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          useSVG="true"
          dot="false"
        /> */}

        {/* <QuickActionsPanel
          bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
          skin="dark"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        />

        <MyCart
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        />

          <QuickPanelToggler />


          <LanguageSelector iconType="" /> */}

        {/* <Level/> */}

        {/* <Notification
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          dot="false"
          listNotify={listNotify}
          totalUnRead = {totalUnRead}
        /> */}

        <UserProfile showAvatar={true} showHi={true} showBadge={false} />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onStateClick: (value) => dispatch(actions.clickOrder(value)),
  };
};

export default connect(null, mapDispatchToProps)(Topbar);