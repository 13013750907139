export const IMAGE_LEVEL = {
    1: 'diamond.png',
    2: 'gem.png',
    3: 'prize.png',
    4: 'prize.png',
    5: 'prize.png'
}

// export const DISCOUNT_WHOLESALE = {
//     1: 60,
//     2: 50,
//     3: 40
// }