import * as actionTypes from "../actions/actionTypes";

const initialState = {
	orderClick: 123,
	listPackage: [],
	listPackageAll: [],
	agencyLevels: [],
	product: {},
	commissionDistributor: {
		total: 136,
		rows: [
		]
	}
};

const setCommissionDistributor = (state, action) => {
	const updatedState = { ...state, commissionDistributor: action.value };
	return updatedState;
};

const setClickOrder = (state, action) => {
	const updatedState = { ...state, orderClick: action.value };
	return updatedState;
};

const setListPackage = (state, action) => {
	const updatedState = { ...state, listPackage: action.value };
	return updatedState;
};
const setListPackageAll = (state, action) => {
	const updatedState = { ...state, listPackageAll: action.value };
	return updatedState;
};

const setAgencyLevel = (state, action) => {
	const updatedState = { ...state, agencyLevels: action.value };
	return updatedState;
};

const setCurrentProduct = (state, action) => {
	const updatedState = { ...state, product: action.value };
	return updatedState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SAVE_COMMISSION_DISTRIBUTOR:
			return setCommissionDistributor(state, action);
		case actionTypes.CLICK_ORDER:
			return setClickOrder(state, action);
		case actionTypes.LIST_PACKAGES_SELL:
			return setListPackage(state, action);
		case actionTypes.LIST_PACKAGES_ALL:
			return setListPackageAll(state, action);
		case actionTypes.LIST_AGENCY_LEVEL:
			return setAgencyLevel(state, action);
		case actionTypes.SET_PRODUCT_CURRENT_DATA:
			return setCurrentProduct(state, action);
		default:
			return state;
	}
};

export default reducer;
