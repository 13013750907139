import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import makeRequest from "../../libs/request";
import { showErrorMessage } from "../../actions/notification";
import { AUTH_TOKEN_KEY } from "../../../app/config/auth";

function Login(props) {
	const { intl } = props;
	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({
		paddingRight: "2.5rem",
		margin: "0 auto",
		marginRight: "10px",
	});

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	return (
		<>
			<div className="kt-login__head">
        <span className="kt-login__signup-label">
          Chưa có tài khoản đại lý
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Đăng ký
        </Link>
      </div>

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>
							{/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
							Đăng nhập
						</h3>
					</div>

					<Formik
						initialValues={{
							email: "",
							password: "",
						}}
						validate={(values) => {
							const errors = {};

							if (!values.email) {
								// https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
								errors.email = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD",
								});
							}

							if (!values.password) {
								errors.password = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD",
								});
							}

							return errors;
						}}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();
							setTimeout(() => {
								login(values.email, values.password)
									.then(({ data }) => {
										disableLoading();
										if (data.signal) {
											props.login(data.data.token);
											window.localStorage.setItem("product_id", 0);
											// props.history.push("/product/chooseProduct");
										} else {
											setSubmitting(false);
											showErrorMessage(data.message);
											// setStatus(
											//   intl.formatMessage({
											//     id: "AUTH.VALIDATION.INVALID_LOGIN"
											//   })
											// );
										}
									})
									.catch((error) => {
										disableLoading();
										setSubmitting(false);
										setStatus(
											intl.formatMessage({
												id: "AUTH.VALIDATION.INVALID_LOGIN",
											})
										);
									});
							}, 1000);
						}}
					>
						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
								{status ? (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								) : (
									<div role="alert" className="alert alert-info">
										<div className="alert-text">
											Sử dụng email hoặc SĐT và mật khẩu để đăng nhập <strong>CRM AIVA</strong>.
										</div>
									</div>
								)}

								<div className="form-group">
									<TextField
										type="email"
										label="Email hoặc SĐT"
										margin="normal"
										className="kt-width-full"
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.email}
										helperText={touched.email && errors.email}
										error={Boolean(touched.email && errors.email)}
										autoFocus
									/>
								</div>

								<div className="form-group">
									<TextField
										type="password"
										margin="normal"
										label="Password"
										className="kt-width-full"
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										helperText={touched.password && errors.password}
										error={Boolean(touched.password && errors.password)}
									/>
								</div>

								<div className="kt-login__actions">
									<button
										id="kt_login_signin_submit"
										type="submit"
										disabled={isSubmitting}
										className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
											"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
										})}`}
										style={loadingButtonStyle}
									>
										Đăng nhập
									</button>
									<Link to="/auth/forgotPassword">Quên mật khẩu?</Link>
								</div>
								<div className="kt-login__actions"></div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
}

export default injectIntl(connect(null, auth.actions)(Login));
