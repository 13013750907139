import * as actionTypes from "./actionTypes";
export const clickOrder = (value) => {
	return {
		type: actionTypes.CLICK_ORDER,
		value,
	};
};

export const listPackage = (value) => {
	return {
		type: actionTypes.LIST_PACKAGES_SELL,
		value,
	};
};
export const listPackageAll = (value) => {
	return {
		type: actionTypes.LIST_PACKAGES_ALL,
		value,
	};
};

export const listAgencyLevel = (value) => {
	return {
		type: actionTypes.LIST_AGENCY_LEVEL,
		value,
	};
};

export const setProductCurrent = (value) => {
	return {
		type: actionTypes.SET_PRODUCT_CURRENT_DATA,
		value,
	};
};

export const actionGetCommissionDistributor = (payload) => async (dispatch) => {
	try {
		console.log("vao 1")
		const response = {
			total: 136,
			rows: [
				{ id: 1, name: "Nam Anh 1", email: "namanh@gmail.com", commission: 123 },
				{ id: 2, name: "Nam Anh 2", email: "namanh@gmail.com", commission: 123 },
				{ id: 3, name: "Nam Anh 3", email: "namanh@gmail.com", commission: 123 },
				{ id: 4, name: "Nam Anh 4", email: "namanh@gmail.com", commission: 123 },
				{ id: 5, name: "Nam Anh 15", email: "namanh@gmail.com", commission: 123 },
				{ id: 6, name: "Nam Anh 1", email: "namanh@gmail.com", commission: 123 },
				{ id: 7, name: "Nam Anh 1", email: "namanh@gmail.com", commission: 123 },
				{ id: 8, name: "Nam Anh 1", email: "namanh@gmail.com", commission: 123 },

			]
		}
		console.log("11111111111", response)
		await dispatch(actionSaveCommissionDistributor(response))
		return response
	} catch (error) {
		console.log("errorrrrrrrrrrrr", error)
	}
}

export const actionSaveCommissionDistributor = (payload) => ({
	type: actionTypes.SAVE_COMMISSION_DISTRIBUTOR,
	payload,
})