import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();
  constructor(props) {
    super(props);
    
  }

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }
  clickMenu = () => {
    document.getElementById("kt_aside_close_btn").hidden = true;
    if (document.getElementById("kt_aside")) {
      document.getElementById("kt_aside").classList.add("kt-aside--on");
    }
    // if (document.getElementsByClassName("kt-grid__item")[0]) {
    //   var new_row = document.createElement("div");
    //   new_row.className = "kt-aside-overlay";
    //   new_row.style.top = "100px";
    //   new_row.style.backgroundColor = "transparent";
    //   document.getElementsByClassName("kt-grid__item")[0].addc(new_row);
    // }
  };

  render() {
    const {
      headerLogo,
      asideDisplay,
      headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes
    } = this.props;

    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link
            to="/"
            style={{ fontSize: "16px", fontWeight: "bold", color: "#fff" }}
          >
            <img style={{ marginTop: "5px" }} alt="logo" src='/images/img-aiva.png' width="105" />

            {/* <img alt="logo" src="/images/logo-kickenglish-3.png" width="110" /> */}
            {/* KickEnglish */}
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">
          {asideDisplay && (
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler"
              onClick={this.clickMenu}
            >
              <span />
            </button>
          )}

          {/* <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu"
  })
});

export default connect(mapStateToProps)(HeaderMobile);
