

const checkPermission = (key) => {
    return 1;
    let listPermisAu = localStorage.getItem("roleUser");
    let listPermis = [];
    if (listPermisAu) {
        listPermis = JSON.parse(listPermisAu);
    } else {
        return 0;
    }
    if (!listPermis) return 0;


    return listPermis.find(x => x.key === key) ? 1 : 0;
}

export default checkPermission;