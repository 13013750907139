import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";
import { showErrorMessage } from "../../actions/notification";
import OtpRegister from './OtpRegister';

function Registration(props) {
  const { intl } = props;
  const [tokenReg, setTokenReg] = useState('');
  const [mobile, setMobile] = useState('');

  if (tokenReg && mobile) {
    return <OtpRegister token={tokenReg} mobile={mobile}/>
  }

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            ĐĂNG KÝ
          </h3>
        </div>

        <Formik
          initialValues={{
            email: "",
            name: "",
            mobile: "",
            password: "",
            confirmPassword: "",
            ref_code: ''
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = 'Vui lòng nhập email'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Vui lòng nhập địa chỉ email hợp lệ'
            }

            if (!values.name) {
              errors.name = 'Vui lòng nhập họ tên'
            }

            if (!values.mobile) {
              errors.mobile = 'Vui lòng nhập số điện thoại'
            }

            if (!values.ref_code) {
              errors.ref_code = 'Vui lòng nhập SĐT Đại lý giới thiệu'
            }

            if (!values.password) {
              errors.password = 'Vui lòng nhập mật khẩu'
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = 'Vui lòng nhập mật khẩu xác nhận'
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Mật khẩu xác nhận lại không khớp!";
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            register(values)
              .then(({ data }) => {
                if (data.signal) {
                  setTokenReg(data.data.token);
                  setMobile(values.mobile);
                } else {
                  setSubmitting(false);
                  showErrorMessage(data.message);
                }
              })
              .catch((error) => {
                setSubmitting(false);
                setStatus('Có lỗi xảy ra');
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label="Họ tên"
                  className="kt-width-full"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  label="Email"
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label="Số điện thoại"
                  className="kt-width-full"
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobile}
                  helperText={touched.mobile && errors.mobile}
                  error={Boolean(touched.mobile && errors.mobile)}
                />
              </div>
              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label="Địa chỉ"
                  className="kt-width-full"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  helperText={touched.address && errors.address}
                  error={Boolean(touched.address && errors.address)}
                />
              </div>
              <div className="form-group">
                <TextField
                  type="text"
                  margin="normal"
                  label="SĐT Đại lý giới thiệu"
                  className="kt-width-full"
                  name="ref_code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ref_code}
                  helperText={touched.ref_code && errors.ref_code}
                  error={Boolean(
                    touched.ref_code && errors.ref_code
                  )}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Mật khẩu"
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label="Xác nhận mật khẩu"
                  className="kt-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>

              <div className="kt-login__actions">

                <Link to="/auth/login">
                  <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                    Đăng nhập
                  </button>
                </Link>

                <button
                  disabled={isSubmitting}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  Đăng ký
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Registration)
);
