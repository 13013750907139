import React from "react";
import { connect, useDispatch } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import HMenu from "./HMenu/HMenu";
import makeRequest from "../../../app/libs/request";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import { setProductCurrent } from "../../../app/store/actions/order";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// listProduct: [],
			product_id: window.localStorage.getItem("product_id"),
		};
		this.headerCommonRef = React.createRef();
	}
	componentDidMount() {
		let options = {};
		if (this.headerCommonRef.current.getAttribute("data-ktheader-minimize") === "1") {
			options["minimize.desktop.on"] = "kt-header--minimize";
			options["offset.desktop"] = 130;
		}
		new KTHeader(this.headerCommonRef.current, options);
	}
	changeText = (e) => {
		e.preventDefault();
		let target = e.target;
		let key = target.name;
		let value = target.value;
		this.setState({
			[key]: value,
			error: {
				title: false,
				html_content: false,
			},
		});
		window.localStorage.setItem("product_id", value);
		window.location.reload();
	};

	render() {
		const { menuHeaderDisplay, headerAttributes, headerClasses } = this.props;
		return (
			<div className={`kt-header kt-grid__item ${headerClasses}`} id="kt_header" ref={this.headerCommonRef} {...headerAttributes}>
				<AnimateLoading />
				{/* <!-- begin: Header Menu --> */}
				{menuHeaderDisplay && <HMenu />}
				{/* <!-- end: Header Menu --> */}
				{/* <!-- begin:: Header Topbar --> */}
				{/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
				{!menuHeaderDisplay && <div />}
				<Topbar />
				{/* <!-- end:: Header Topbar --> */}
			</div>
		);
	}
}

const mapStateToProps = (store) => ({
	headerClasses: builder.selectors.getClasses(store, {
		path: "header",
		toString: true,
	}),
	headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
	menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, "header.menu.self.display"),
	fluid: objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
});

const mapDispatchToProps = dispatch => {
	return {
	  setProduct: (data) => dispatch(setProductCurrent(data)),
	}
  }

export default connect(mapStateToProps, mapDispatchToProps)(Header);
