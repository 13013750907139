import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { verifyRegister } from "../../crud/auth.crud";
import { showErrorMessage, showSuccessMessage } from "../../actions/notification";

function OtpRegister(props) {
  const { intl, token, mobile } = props;
  const history = useHistory();

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            XÁC THỰC
          </h3>
          <h5>Vui lòng nhập mã xác thực được gửi tới số điện thoại {mobile}</h5>
        </div>

        <Formik
          initialValues={{
            otp: ''
          }}
          validate={values => {
            const errors = {};
            if (!values.otp) {
              errors.otp = 'Vui lòng nhập mã xác thực'
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            verifyRegister({
                token,
                otp: values.otp
            })
              .then(({ data }) => {
                if (data.signal) {
                  showSuccessMessage('Xác thực thành công, vui lòng chờ hệ thống xét duyệt tài khoản');
                  history.push("/auth/login");
                } else {
                    setSubmitting(false);
                    showErrorMessage(data.message);
                }
              })
              .catch((error) => {
                setSubmitting(false);
                setStatus('Có lỗi xảy ra');
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label="Mã xác thực"
                  className="kt-width-full"
                  name="otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.otp}
                  helperText={touched.otp && errors.otp}
                  error={Boolean(touched.otp && errors.otp)}
                />
              </div>

              <div className="kt-login__actions">

                <Link to="/auth/login">
                  <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                    Xác thực
                  </button>
                </Link>

                <button
                  disabled={isSubmitting}
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  Xác thực
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(OtpRegister)
);
